import {
  Box,
  Button,
  Dialog,
  Group,
  Image,
  MantineProvider,
  Modal,
  Progress,
  Text,
  createTheme,
} from "@mantine/core";
import { Helmet } from 'react-helmet';
import { notifications } from "@mantine/notifications";
import React, { createContext, useContext, useEffect, useMemo } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Socket, connect } from "socket.io-client";
import usePersistState from "../hooks/usePersistState";
import { GET_ALL_USERS } from "../services/users";
import { useAuth } from "./auth.context";
import ReactGA from 'react-ga';
import { useLocation, useNavigate } from "react-router-dom";
import ChoosePlan from "../pages/choose_plan";
import { useQuery } from "../utility/util";
import Cookies from 'js-cookie'
import PublicPage from "../pages/public";
import CookieConsent from "react-cookie-consent";
import { GET_COMPANY_INTEGRATION } from "../services/companies";

interface ConfirmDialogProps {
  (
    props: { text: string; buttonText?: string },
    callback: (props: { confirmed: boolean }) => void
  ): void;
}
interface ConfigsProps {
  primary?: string; navbar?: string; image?: string; icon?: string;
  contrast?: string; brand?: string;
  name?: string;
}
interface AppContextProps {
  confirmDialog: ConfirmDialogProps;
  setColorScheme: (colorScheme: "dark" | "light") => void;
  socket: Socket;
  configs: ConfigsProps;
  mobileApp?: any;
  setConfigs: (vl: ConfigsProps) => any;
  registration?: ServiceWorkerRegistration;
  agents: any[];
  loadAgents: () => void;
  openedSidebar?: boolean;
  toggleOpenedSidebar?: () => void;
  expandImage: (url: string) => void;
  expandText: (text: string) => void;
  newOrder: any;
  setNewOrder: (params: any) => void;
  notifyGaEvent: (params: any) => void;
  startPlan: (start?: boolean) => void;
}

const AppContext = createContext<AppContextProps>({} as AppContextProps);

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const lsColorScheme = localStorage.getItem("@retainr/colorScheme") as
    | "light"
    | "dark";

  const [openedConfirm, setOpenedConfirm] = React.useState(null);
  const [newOrder, setNewOrder] = React.useState(null);
  const [startingPlan, setStartingPlan] = React.useState(false);
  const [importingFiles, setImportingFiles] = React.useState([]);
  const [agents, setAgents] = React.useState<any[]>([]);
  const [configs, setConfigs] = React.useState<ConfigsProps>({});
  const [openImage, setOpenImage] = React.useState<string>("");
  const [openText, setOpenText] = React.useState<string>("");
  const [mobileApp, setMobileApp] = React.useState<any>({});
  const [colorScheme, setColorScheme] = React.useState<"dark" | "light">(
    lsColorScheme || "light"
  );
  const [registration, setRegistration] =
    React.useState<ServiceWorkerRegistration>(null);

  const query = useQuery();

  let fbc = query.get("fbclid");

  const [socket, setSocket] = React.useState<Socket>(null);

  const { role, user: currentUser, companyHost, loadUserData, user, userData } = useAuth();

  const { pathname } = useLocation();
  const navigate = useNavigate();


  const gaTrackings = [
    "G-LY81V86E4J",
    companyHost?.ga,
  ].filter(nn => nn);

  const [openedSidebar, setOpenedSidebar] = usePersistState<boolean>(
    "@retainr/opened-sidebar",
    false
  );

  const loadAgents = (setLoading = true) => {
    if (role) {
      GET_ALL_USERS()
        .then((dt) => {
          setAgents(
            dt
              .filter(u => u?.profile?.title !== "Client")
              .sort((a, b) => (a.name > b.name ? 1 : -1))
          );
        })
        .catch((err) => {
          notifications.show({
            title: "Oops",
            message: err.message,
            color: "red",
          });
        });
    }
  };

  const confirmDialog: ConfirmDialogProps = (
    { text, buttonText = "Confirm" },
    callback
  ) => {
    setOpenedConfirm({ text, buttonText, callback });
  };

  const toggleOpenedSidebar = () => {
    setOpenedSidebar(!openedSidebar);
  };

  const notifyGaEvent = (params: any) => {
    for (let trackingId of gaTrackings) {
      ReactGA.event(params, trackingId);
    }
  }

  useEffect(() => {
    GET_COMPANY_INTEGRATION("mobile-app")
      .then((res) => { setMobileApp(res) })
      .catch(err => { })
  }, [userData])

  useEffect(() => {
    for (let trackingId of gaTrackings) {
      ReactGA.initialize(trackingId);
      ReactGA.set({ debug: false });
    }
  }, [JSON.stringify(gaTrackings)]);

  useEffect(() => {
    for (let trackingId of gaTrackings) {
      ReactGA.pageview(pathname, [trackingId]);
    }
  }, [JSON.stringify(gaTrackings), pathname]);

  // useEffect(() => {
  //   if ("serviceWorker" in navigator) {
  //     navigator.serviceWorker
  //       .register("/service-worker.js")
  //       .then((registration) => {
  //         setRegistration(registration);
  //       })
  //       .catch((error) => {
  //         console.error("Service worker registration failed:", error);
  //       });
  //   }
  // }, []);


  useEffect(() => {
    if (fbc) Cookies.set("fbc", fbc);
  }, [fbc])

  useEffect(() => {
  }, [socket]);

  useEffect(() => {
    localStorage.setItem("@retainr/colorScheme", colorScheme);
  }, [colorScheme]);

  useEffect(() => {
    if (currentUser) {
      const s = connect(process.env.REACT_APP_SERVER_URL, {
        extraHeaders: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      });
      setSocket(s);
    }
  }, [currentUser]);

  useEffect(loadAgents, [role]);

  useEffect(() => {
    setConfigs(c => ({
      ...c,
      name: userData?.company?.name,
      primary: companyHost?.appearance?.colors?.primary || userData?.company?.appearance?.colors?.primary || "#FF4D17",
      navbar: companyHost?.appearance?.colors?.navbar || userData?.company?.appearance?.colors?.navbar || "white",
      image: companyHost?.image ?? userData?.company?.image,
      icon: companyHost?.icon ?? userData?.company?.icon,
    }))
  }, [companyHost, userData])

  const value = useMemo<AppContextProps>(() => {
    return {
      confirmDialog,
      setColorScheme,
      socket,
      mobileApp,
      registration,
      agents,
      openedSidebar,
      loadAgents,
      toggleOpenedSidebar,
      expandImage: (url) => setOpenImage(url),
      expandText: (text) => setOpenText(text),
      newOrder,
      setNewOrder,
      notifyGaEvent,
      startPlan: (start = true) => {
        setStartingPlan(start);
        if (!start) loadUserData();
      },
      configs: {
        ...configs,
        brand: {
          "black": "#2C2C2C",
          "green": "#B7CD4B",
          "yellow": "#FFD540",
          "orange": "#FF4D18",
          "white": "#FAFAFA",
          "blue": "#EDF8FD",
          "pink": "#FFF0F6",
        }[configs.navbar ?? "white"],
        contrast: {
          "black": "#FBFBFB",
          "green": "#FBFBFB",
          "yellow": "#000000",
          "orange": "#FBFBFB",
          "blue": "#333333",
          "pink": "#333333",
          "white": "#000000",
        }[configs.navbar ?? "white"],
      },
      setConfigs: (c) => {
        setConfigs(v => ({ ...v, ...c }));
      },
    };
  }, [
    configs,
    setConfigs,
    confirmDialog,
    setColorScheme,
    socket,
    newOrder,
    registration,
    agents,
    openedSidebar,
    toggleOpenedSidebar,
  ]);

  const theme = createTheme({
    // colorScheme,
    // loader: "dots",
    fontFamily: "Inter, sans-serif",
    // datesLocale: "en",
    colors: {
      white: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#EFEFEF", "#FFFFFF", "#FFFFFF"],
      orange: [
        configs.primary ?? "#3a3a3a",
        configs.primary ?? "#3a3a3a",
        configs.primary ?? "#3a3a3a",
        configs.primary ?? "#3a3a3a",
        configs.primary ?? "#3a3a3a",
        configs.primary ?? "#3a3a3a",
        configs.primary ?? "#ff4d18",
        configs.primary ?? "#ff4d1855",
        configs.primary ?? "#3a3a3a",
        configs.primary ?? "#3a3a3a",
      ],
    },
    headings: { fontWeight: "bold" },
    primaryColor: "orange",
    components: {
      Button: { styles: { root: { borderRadius: 8 } } },
      ActionIcon: { styles: { root: { borderRadius: 8 } } },
      Paper: { styles: { root: { border: '1px solid black', borderRadius: 8 } } },
    },
  });

  return (
    <MantineProvider
      withCssVariables
      theme={theme}
    >
      <AppContext.Provider value={value}>
        <>
          <>
            <Dialog
              opened={Boolean(openedConfirm)}
              withCloseButton={false}
              onClose={() => setOpenedConfirm(null)}
              size="lg"
              radius="md"
              zIndex={9999}
            >
              <Text size="sm" mb="sm">
                {openedConfirm?.text}
              </Text>

              <Group align="flex-end">
                <Button variant="outline" color="gray"
                  onClick={() => setOpenedConfirm(null)}
                >Cancel</Button>
                <Button
                  color="orange"
                  onClick={() => {
                    openedConfirm?.callback &&
                      openedConfirm?.callback({ confirmed: true });
                    setOpenedConfirm(null);
                  }}
                >
                  {openedConfirm?.buttonText}
                </Button>
              </Group>
            </Dialog>
          </>
        </>
        {(companyHost && !companyHost.active) ? <PublicPage /> : children}
        <Helmet>
          {configs.name && <title>{configs.name}</title>}
          {configs.icon
            ? <link rel="icon" href={configs.icon} />
            : <link rel="icon" href={"/icon.png"} />}
        </Helmet>
        {/* {userData?.user && role?.profile?.title !== "Client" && process.env.REACT_APP_SUPORT_BOARD_ID && <Helmet>
          <script id="chat-init" src={`https://cloud.board.support/account/js/init.js?id=${process.env.REACT_APP_SUPORT_BOARD_ID}&name=${userData.user.name}`}></script>
        </Helmet>} */}
        <Dialog opened={importingFiles.length > 0}>
          {importingFiles.map((imp) => (
            <div>
              <div style={{ textAlign: "right" }}>
                <Text c="orange" size="sm">
                  {imp.done}/{imp.total}
                </Text>
              </div>
              <Progress color="orange" value={imp.percent} />
              <Text size="xs" style={{ textAlign: "right" }}>
                {{ themes: "Importação de pautas" }[imp.type]} -{" "}
                {imp.origin.toUpperCase()}
              </Text>
            </div>
          ))}
        </Dialog>
        <Modal
          opened={!!openImage}
          onClose={() => setOpenImage(null)}
          fullScreen
          size="xl"
        >{openImage && <TransformWrapper
          limitToBounds
        >
          {/* {({ zoomIn, zoomOut, resetTransform, ...rest }) => ( */}
          <TransformComponent>
            <Image
              // onClick={() => zoomIn()}
              src={openImage} height={"90vh"} width="100%" fit="contain" />
          </TransformComponent>
          {/* )} */}
        </TransformWrapper>}</Modal>
        <Modal
          opened={!!openText}
          onClose={() => setOpenText(null)}
          size="md"
        ><Box style={{ border: 10, background: "#F4F4F4" }} p="lg">
            {openText}
          </Box>
        </Modal>
        <Modal
          opened={startingPlan}
          overlayProps={{ backgroundOpacity: 0.1 }}
          onClose={() => setStartingPlan(false)}
          size="90vw"
          withCloseButton={false}
          styles={{ content: { background: '#FBFBFB' } }}
        >
          <ChoosePlan onClose={() => setStartingPlan(false)} />
        </Modal>
        <CookieConsent
          style={{
            background: configs.primary ?? '#ff4d18', fontWeight: 'bold'
          }}
          buttonStyle={{
            color: "white",
            background: 'transparent',
            fontWeight: "bold",
            border: '2px solid white',
            borderRadius: 8,
            padding: '5px 30px'
          }}
        >
          This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
      </AppContext.Provider>
    </MantineProvider>
  );
};

export const useApp = () => useContext(AppContext);
