import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid, Group, Paper,
  Popover,
  Progress,
  Text,
  Title, Tooltip, UnstyledButton
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { FaCheck, FaChevronLeft, FaInfoCircle } from "react-icons/fa";
import { Link, Location, Navigate, useLocation, useNavigate } from "react-router-dom";
import InputField, { InputFieldTypes } from "../components/input_field";
import { useAuth } from "../contexts/auth.context";
import { api } from "../services/api";
import { extenseRecurrenceType, phoneCountryCodes, useQuery } from "../utility/util";
import { GET_ALL_PLANS } from "../services/plans";
import { PaymentForm } from "../components/payment_form";
import { useApp } from "../contexts/app.context";
import { SUBSCRIBE } from "../services/auth";
import RenderImage from "../components/render_image";
import { GET_STRIPE_CONFIGS, SAVE_PAYMENT_METHOD } from "../services/payments";
import { Config } from "../constants/config.constant";

declare global {
  interface Window {
    promotekit_referral: any
  }
}

export default function Signup({ order = null, service = null, customer = false, shareLinkId = null, partner = false }) {
  const { user, signIn, companyHost, ip } = useAuth();
  const { notifyGaEvent } = useApp();
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 900px)');
  const [selectedRecurrencyType, setSelectedRecurrencyType] = useState<string>(Config.DEFAULT_RECURRENCY_TYPE);

  if (companyHost) {
    if (companyHost?.fastGeneration) customer = false;
    else customer = true;
  }

  const [formData, setFormData] = useState<any>({
    company: {
      type: "1",
      employees: "1"
    }
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [agree, setAgree] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [steps, setSteps] = useState<any[]>([]);
  const [plans, setPlans] = useState<any[]>([]);

  const selectedPlan = plans.find(p => p._id === formData?.plan);

  const state = location.state as { from: Location };
  const from = state ? `${state.from.pathname}?signed=true` : "/?signed=true";

  const query = useQuery();
  const queryMail = query.get("email");
  const referral = query.get("referral") ?? window.promotekit_referral;

  const validTab = customer
    ? [
      service ? () => true : null,
      () => formData?.general?.name &&
        formData?.general?.email &&
        // formData?.general?.register_number &&
        formData?.general?.phone_code &&
        formData?.general?.phone &&
        formData?.general?.password
    ].filter(nn => nn)
    : [
      () => formData?.general?.name &&
        formData?.general?.email &&
        // formData?.general?.register_number &&
        formData?.general?.phone &&
        formData?.general?.phone_code &&
        formData?.general?.password,
      () => formData?.company?.name && formData?.company?.type && formData?.company?.employees,
      partner ? () => formData?.plan : () => true,
      partner ? () => formData?.coupon?.length > 3 : () => {
        return formData?.payment?.card &&
          formData?.payment?.card?.number &&
          formData?.payment?.card?.exp_month &&
          formData?.payment?.card?.exp_year &&
          formData?.payment?.card?.cvc
      },
    ].filter(nn => nn);

  const signup = async () => {
    if (customer) {
      finishSignup();
    } else {
      if (agree) {
        setLoading(true)
        if (formData?.payment.method === "new-card") {
          try {
            const { retainr } = await GET_STRIPE_CONFIGS("retainr")
            const stripe = require('stripe')(retainr);
            const pm = await stripe.paymentMethods.create({
              type: 'card',
              card: formData?.payment?.card,
            })
            const method = await SAVE_PAYMENT_METHOD("retainr", pm, { email: formData?.general?.email, name: formData?.general?.name })
            finishSignup(method._id, { push: true });
          } catch (err) {
            setLoading(false)
            notifications.show({ message: err?.message ?? "Stripe not configured" });
          }
        } else {
          finishSignup(formData?.payment?.method);
        }
      } else {
        notifications.show({ message: "To continue you need to agree with Terms of Service and Refund Policy", color: "yellow" })
      }
    }
  }

  const finishSignup = (method = null, others = {}) => {
    setLoading(true)

    api.post(
      customer ? `/auth/signup` : `/auth/signup/company`,
      customer
        ? { general: formData?.general, custom: formData?.custom, job: order?._id, service: service?._id }
        : {
          ...formData,
          payment: { method, ...others },
          referral,
          share_link: shareLinkId,
          existentCompany: companyHost?.fastGeneration ? companyHost.id : null
        }
    )
      .then(({ data: { user } }) => {
        if (selectedPlan) {
          try {
            (window as any).fbq('track', 'Purchase', {
              ip,
              name: formData?.general?.name,
              email: formData?.general?.email,
              phone: `+${formData?.general?.phone_code}${formData?.general?.phone.replace(/\(|\)|\-|\.| /g, "")}`,
              plan: selectedPlan?.service?.plan_name,
              currency: selectedPlan?.service?.currency,
              value: selectedPlan?.service?.unity_price
            });
          } catch (ignored) { }
          try {
            (window as any).ttq.track('Purchase', {
              content_type: "subscription",
              name: formData?.general?.name,
              email: formData?.general?.email,
              phone: `+${formData?.general?.phone_code}${formData?.general?.phone.replace(/\(|\)|\-|\.| /g, "")}`,
              plan: selectedPlan?.service?.plan_name,
              currency: selectedPlan?.service?.currency,
              value: selectedPlan?.service?.unity_price
            });
          } catch (ignored) { }
          try {
            (window as any).gtag('event', 'purchase', {
              event_name: "purchase",
              name: formData?.general?.name,
              email: formData?.general?.email,
              phone: `+${formData?.general?.phone_code}${formData?.general?.phone.replace(/\(|\)|\-|\.| /g, "")}`,
              plan: selectedPlan?.service?.plan_name,
              currency: selectedPlan?.service?.currency,
              value: selectedPlan?.service?.setup_value
            })
          } catch (ignored) { }
        }
        try {
          let wd: any = window;
          // PromoteKit
          wd.promotekit.refer(formData?.general?.email);

          // Rewardful
          let rewardful = wd.rewardful;
          rewardful('convert', { email: formData?.general?.email });
        } catch (ignored) { console.log({ ignored }) }

        setLoading(false);
        if (customer) {
          signIn(user, () => navigate(
            order ? `/orders/${order._id}` : service ? `/?service=${service._id}` : "/",
            { replace: true }
          ))
        } else {
          signIn(user, () => navigate("/welcome", { replace: true }));
        }
      })
      .catch(err => {
        setLoading(false);
        notifications.show({ title: "Oops", message: err.message, color: 'red' })
      })
  }

  const navigateToTab = index => {
    if (steps[index - 1]?.onNext) steps[index - 1]?.onNext();

    if (index === steps.length) signup();
    else setSelectedTab(index);
  }

  const handleChangeGeneral = dt => setFormData(fd => ({ ...fd, general: { ...fd?.general, ...dt } }))
  const handleChangeCompany = dt => setFormData(fd => ({ ...fd, company: { ...fd?.company, ...dt } }))
  const handleChangeCustom = dt => setFormData(fd => ({ ...fd, custom: { ...fd?.custom, ...dt } }))
  // const handleChangeOthers = dt => setFormData(fd => ({...fd, others: {...fd?.others, ...dt}}))

  useEffect(() => {
    if (companyHost?.fastGeneration) setFormData(fd => ({
      ...fd,
      general: {
        ...fd?.general,
        name: companyHost.name,
      },
      company: {
        ...fd?.company,
        name: companyHost.name,
      },
    }))
  }, [companyHost]);

  useEffect(() => {
    handleChangeGeneral({ email: queryMail })
  }, [queryMail]);

  useEffect(() => {
    GET_ALL_PLANS()
      .then(res => {
        setPlans(res.sort((a, b) => a.service.unity_price > b.service.unity_price ? 1 : -1));
      })
      .catch(() => { })
  }, [])

  useEffect(() => {
    let pls = plans.sort((a, b) => a.service.recurrence_type === "year" && b.service.recurrence_type !== "year" ? -1 : 1)
    setFormData(fd => ({ ...fd, plan: pls[0]?._id }))
  }, [plans])

  const ServiceStep = service && <Box>
    <RenderImage src={service?.cover_image} width="100%" />

    {
      order
        ? <Text mt="xs" size="md" fw="bold" c="orange">{order.currency} {parseFloat(order.total_price).toFixed(2)}</Text>
        : (service.pricing_structure === "custom" || service.pricing_table)
          ? <Text mt="xs" size="md" fw="bold" c="orange">On Demand ({service.currency})</Text>
          : <Text mt="xs" size="md">From {service.currency} {parseFloat(service.unity_price ?? 0).toFixed(2)}</Text>
    }
    <Text c="gray" size="xs">{service.plan_type === "subscription" ? extenseRecurrenceType(service.recurrence_type) : "One-time"}</Text>
    <Text mt="xs" size="sm">{(service.description ?? "").split("\n").map((d) => <div style={{ minHeight: 14 }}>{d}</div>)}</Text>
  </Box>

  const FirstStep = <Box>
    <InputField
      title="Company/Brand Name"
      mb="lg"
      name="name"
      maxLength={30}
      value={formData?.company?.name}
      onChange={handleChangeCompany}
    />
    <InputField
      mb="lg"
      name="type"
      value={formData?.company?.type}
      fieldType={InputFieldTypes.BOX}
      onChange={handleChangeCompany}
      options={[
        { label: "Personal account", value: "1" },
        { label: "Business account", value: "2" },
      ]}
    />
    <InputField
      title="Number of collaborators"
      mb="lg"
      name="employees"
      value={formData?.company?.employees}
      fieldType={InputFieldTypes.BOX}
      onChange={handleChangeCompany}
      options={[
        { label: "1", value: "1" },
        { label: "2-10", value: "2-10" },
        { label: "11-50", value: "11-50" },
        { label: "50+", value: "50+" },
      ]}
    />
  </Box>;
  const SecondStep = <Box>
    <InputField
      title="Your Name"
      mb="lg"
      autoComplete="off"
      name="name"
      maxLength={30}
      value={formData?.general?.name}
      onChange={handleChangeGeneral}
    />
    <InputField
      title="Work email"
      description="Use your professional email, so that you won't miss key client notifications"
      mb="lg"
      name="email"
      maxLength={80}
      autoCapitalize="off"
      value={formData?.general?.email}
      onChange={handleChangeGeneral}
    />
    <Group style={isMobile ? { display: 'block' } : {}}>
      <InputField
        style={isMobile ? {} : { flex: 0.3 }}
        title="Code"
        mb="lg"
        name="phone_code"
        autoComplete="tel-country-code"
        fieldType={InputFieldTypes.SELECT}
        value={formData?.general?.phone_code}
        onChange={handleChangeGeneral}
        nothingFound="No country code found"
        options={phoneCountryCodes}
      />
      <InputField
        style={isMobile ? {} : { flex: 0.7 }}
        title="Phone"
        mb="lg"
        name="phone"
        value={formData?.general?.phone}
        onChange={handleChangeGeneral}
      />
    </Group>
    {customer && (companyHost?.customFields || [])
      .filter(field => field.title)
      .map(field => (
        <InputField mb="md" name={field._id} value={{ ...formData?.custom }[field._id]} onChange={handleChangeCustom} title={field.title} />
      ))}
    <InputField
      title="Password"
      mb="lg"
      name="password"
      fieldType={InputFieldTypes.PASSWORD}
      value={formData?.general?.password}
      onChange={handleChangeGeneral}
    />
  </Box>;
  const ThirdStep = partner
    ? <Box>
      <Title order={3} mt="md">Insert Lifetime Deal Code (e.g. AppSumo)</Title>

      <InputField
        title="Lifetime Deal Code (e.g. AppSumo)"
        mt="lg"
        mb="lg"
        name="coupon"
        value={formData?.coupon}
        onChange={({ coupon }) => setFormData(fd => ({ ...fd, coupon }))}
      />
    </Box>
    : <Box>
      <Box>
        {/* <UnstyledButton mb="md" style={{width: '100%'}} onClick={() => {
          setFormData(fd => ({...fd, plan: null}))
      }}>
          <Paper shadow='xs' p="md" style={{border: !formData?.plan ? `3px solid #FF4D17` : '1px solid black'}}>
              <Title order={3} mt="sm">Free Plan (Limited Resources)</Title>
          </Paper>
      </UnstyledButton> */}

        <Group pb="md" mb="md" style={{ justifyContent: 'center' }} align='center'>
          <Group gap={5} style={{ borderRadius: 40, padding: '10px', border: '1px solid #DFDFDF' }}>
            <Button
              style={{ borderRadius: 20, width: 100, border: selectedRecurrencyType === "month" ? '1px solid #DFDFDF' : 0 }}
              color="orange"
              onClick={() => setSelectedRecurrencyType("month")}
              variant={selectedRecurrencyType === "month" ? "filled" : "light"} size="xs">Monthly</Button>
            <Button
              style={{ borderRadius: 20, width: 100, border: selectedRecurrencyType === "year" ? '1px solid #DFDFDF' : 0 }}
              color="orange"
              onClick={() => setSelectedRecurrencyType("year")}
              variant={selectedRecurrencyType === "year" ? "filled" : "light"} size="xs">Yearly</Button>
          </Group>
        </Group>

        <Grid>
          {plans
            .filter(p => (selectedRecurrencyType === "month" && !p.service.recurrence_type) || p.service.recurrence_type === selectedRecurrencyType)
            .map(plan => (
              <Grid.Col span={{ base: 12, md: 4 }}>
                <UnstyledButton mb="md" style={{ width: '100%' }} onClick={() => {
                  setFormData(fd => ({ ...fd, plan: plan._id }))
                }}>
                  <Paper shadow='xs' p="md" style={{ border: selectedPlan?._id === plan._id ? `3px solid #FF4D17` : '1px solid black' }}>
                    <Text c="gray" size="sm">{plan.service.currency} {parseFloat(plan.service.unity_price).toFixed(2)}</Text>
                    <Text c="gray" size="xs">{plan.service.plan_type === "subscription" ? extenseRecurrenceType(plan.service.recurrence_type) : "One-time"}</Text>
                    <Text c="gray" size="sm" mt="md">{plan.service.description}</Text>
                    <Title order={4} mt="sm">{plan.service.plan_name}</Title>
                    <Group align='center' justify='flex-end' mt="xs">
                      {(plan.service.badges ?? []).map(s => (<Badge variant='filled' c={s.textColor} color={s.color}>{s.text}</Badge>))}
                    </Group>
                  </Paper>
                </UnstyledButton>
              </Grid.Col>
            ))}
        </Grid>
      </Box>
    </Box>;
  const FourthStep = <Box>
    <Grid>
      <Grid.Col span={{ base: 12, md: 12 }}>
        <Paper style={{ borderColor: "#DFDFDF" }} p="xl">
          <Group align="flex-start">
            <Box style={{ flex: 1 }}>
              <Title order={3}>{selectedPlan?.service?.plan_name}</Title>
              <Text c="black" size="sm">{selectedPlan?.service?.description}</Text>
            </Box>
            <Box>
              <Group align='flex-end' gap="1px">
                <Title c="black" size="22px">{selectedPlan?.service?.currency} {parseFloat(selectedPlan?.service?.unity_price).toFixed(2)}</Title>
                <Text c="black" size="sm">/ {selectedPlan?.service?.plan_type === "subscription" ? (selectedPlan?.service?.recurrence_type ?? "month") : "One-time"}</Text>
              </Group>
              {/* <Text mb="md" c="gray" size="sm">Per user</Text>
                      <InputField
                          title="Add Users"
                          name="seats"
                          fieldType={InputFieldTypes.QUANTITY}
                          value={formData.seats}
                          onChange={({seats}) => {
                              let minimun = (planData?.seats || []).length;
                              minimun = (minimun > 1 ? minimun : 1);
                              if(parseInt(seats) >= minimun){
                                  setFormData(fd => ({...fd, seats}));
                              }
                          }}
                          size="xs"
                      /> */}
            </Box>
          </Group>
        </Paper>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 7 }}>
        <Paper style={{ borderColor: "#DFDFDF" }} p="xl">
          {
            (selectedPlan?.service?.unity_price ?? 0) > 0 && <>
              <Group>
                <Title style={{ flex: 1 }} order={4}>Payment Method</Title>
                {selectedPlan?.service?.charge_setup && <Popover>
                  <Popover.Target>
                    <Tooltip label="Why do you need to put card information?">
                      <ActionIcon variant="light" color="yellow"><FaInfoCircle /></ActionIcon>
                    </Tooltip>
                  </Popover.Target>
                  <Popover.Dropdown style={{ width: 300 }}>
                    <Text ta="center" fw="bold" size="sm" mb="xs">Don't worry, you wont be charged until your free trial ends</Text>
                    <Text ta="center" size="xs">We ask for your payment information to provide a seamless subscription experience. You can cancel anytime before 7 days trial ends to avoid being charged.</Text>
                  </Popover.Dropdown>
                </Popover>}
              </Group>
              <Box mt="sm">
                <PaymentForm
                  showMethods={false}
                  data={formData?.payment}
                  onChange={dt => {
                    setFormData(fd => ({ ...fd, payment: { ...fd.payment, ...dt } }))
                  }}
                />
              </Box>
            </>
          }
        </Paper>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 5 }}>
        <Paper style={{ borderColor: "#DFDFDF" }} p="xl">
          <Title order={4}>Total</Title>

          <Paper mt="md" p="md" style={{ background: '#EFEFEF', borderColor: "#DFDFDF" }}>
            <Text size="md">Due Today</Text>
            <Divider c="#DFDFDF" mt="md" mb="md" />
            <Text size="xs">Web Platform {selectedPlan?.mobile_app ? " + Full Mobile APP" : ""} White-label</Text>
            <Text size="xs" mt="xs" c="gray">{selectedPlan?.service?.currency} {(selectedPlan?.service?.unity_price ?? 0).toFixed(2)}{selectedPlan?.service?.plan_type === "subscription" && selectedPlan?.service?.charge_setup ? ` after 7 days trial` : ''}</Text>
            {<>
              <Divider c="#DFDFDF" mt="md" mb="md" />
              <Text size="xs">Total Amount Due Today</Text>
              <Text size="xs" mt="xs" fw="bold">{selectedPlan?.service?.currency} {
                (selectedPlan?.service?.plan_type === "subscription" && selectedPlan?.service?.charge_setup)
                  ? (selectedPlan?.service?.setup_value ?? 0).toFixed(2)
                  : (selectedPlan?.service?.unity_price ?? 0).toFixed(2)
              }</Text>
            </>}
          </Paper>
        </Paper>
      </Grid.Col>
    </Grid>
    <Checkbox
      checked={agree}
      mt="md"
      onChange={() => setAgree(a => !a)}
      label={<Text size="xs" c="gray" mb="md">I agree to the <Link to="https://www.retainr.io/terms-conditions" target="_blank">Terms of Service and Refund Policy</Link></Text>}
    />
  </Box>;

  const activeForm: any = (
    customer ?
      [
        service ? { principal: ServiceStep } : null,
        { principal: SecondStep },
      ].filter(nn => nn)
      : [
        { principal: SecondStep },
        { principal: FirstStep },
        { principal: ThirdStep },
        { principal: FourthStep },
      ].filter(nn => nn)
  )[selectedTab]

  useEffect(() => {
    if (customer) {
      setSteps([
        service ? { title: service.plan_name, subtitle: `Get access to ${companyHost?.name ?? "Retainr"}` } : null,
        { title: "Sign Up", subtitle: `Get access to ${companyHost?.name ?? "Retainr"}` },
      ].filter(nn => nn))
    } else {
      setSteps([
        {
          subtitle: "2 minutes away from using Retainr", onNext: () => {
            setFormData(fd => {
              SUBSCRIBE({ referral, name: fd?.general?.name, email: fd?.general?.email, phone: fd?.general?.phone, phone_code: fd?.general?.phone_code })
                .then(() => { })
                .catch(() => { })
              return fd;
            })
          }
        },
        { title: "Almost there! 🏁", subtitle: "🔓 Just a step away from using Retainr" },
        { title: "Start Your 7-Day Trial for Just $1", subtitle: "Pricing that suits all." },
        { title: selectedPlan?.service?.plan_name ?? "Get Retainr.io" },
      ])
    }
  }, [customer, service, selectedPlan])

  return !!user
    ? <Navigate to="/" state={{ from }} />
    : (
      <>
        <Helmet>
          <title>{companyHost?.name ? `Sign Up - ${companyHost.name}` : 'Create an Account'} | Retainr.io</title>
        </Helmet>
        <div style={{
          backgroundImage: `url(/assets/onboarding.png)`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundAttachment: 'fixed',
          position: 'relative',
          minHeight: '100vh',
          zIndex: 0,
          ...(
            isMobile
              ? {
                padding: 20,
              }
              : {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: activeForm?.secondary ? '10vh 10vw' : '10vh 20vw',
              }
          )
        }}>
          <div style={{ position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, background: '#00000055', zIndex: -1 }}></div>
          <Paper p="md" style={{ borderColor: '#DFDFDF' }}>
            {(isMobile && activeForm?.secondary)
              ? activeForm?.secondary
              : <Grid>
                <Grid.Col span={{ base: 12, md: activeForm?.secondary ? 7 : 12 }}>
                  <div style={{ display: 'flex', margin: '40px 0', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <img alt="retainr" src={companyHost?.image ? companyHost?.image : `/logo.png`} style={{ maxHeight: 80, maxWidth: '180px' }} />
                  </div>

                  <Group style={{ padding: isMobile ? '0 4px' : '0 60px' }}>
                    <ActionIcon onClick={() => { if (selectedTab === 0) { navigate("/login") } else { setSelectedTab(i => i - 1) } }}><FaChevronLeft /></ActionIcon>
                    <Box style={{ flex: 1 }}>
                      {steps[selectedTab]?.title && <Title ta="center" c="black" order={2}>{steps[selectedTab]?.title}</Title>}
                      {steps[selectedTab]?.subtitle && <Text ta="center" c="gray" size="sm">{steps[selectedTab]?.subtitle}</Text>}
                    </Box>
                    <ActionIcon style={{ visibility: 'hidden' }}><FaChevronLeft /></ActionIcon>
                  </Group>

                  <Box style={{ padding: isMobile ? '20px 10px 20px 10px' : '40px 60px 40px 60px' }}>
                    <Box style={{ padding: '0 0 40px 0' }}>
                      <Progress
                        value={(((selectedTab ?? 0) + 1) / (steps.length)) * 100}
                        color="orange"
                      />
                    </Box>
                    <form onSubmit={e => { e.preventDefault(); navigateToTab(selectedTab + 1) }} style={{ overflowY: 'auto' }}>
                      {activeForm?.principal}
                      <Box mt="xl">
                        <Button
                          className='draw-button'
                          type="submit"
                          loading={loading}
                          disabled={validTab[selectedTab] && !validTab[selectedTab]()}
                          color="orange"
                          fullWidth
                          rightSection={<FaCheck />}
                          size="lg">{selectedTab + 1 === steps.length ? customer ? "Sign Up" : "Checkout " : "Continue"}
                        </Button>
                      </Box>
                    </form>
                  </Box>
                </Grid.Col>
                {activeForm?.secondary && !isMobile && <Grid.Col span={{ base: 12, md: 5 }}>
                  {activeForm.secondary}
                </Grid.Col>}
              </Grid>}
          </Paper>
        </div>
      </>
    );
};
